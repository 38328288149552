import axios from 'axios';

const url = 'https://volkaapp.com/api/';
const devUrl = 'https://dev.volkaapp.com/api/';
const token = localStorage.getItem('authorizationToken');
const headers = {
    'Content-Type': 'application/json',
};

if (token) {
    headers.Authorization =  'Bearer ' + token;
}

export default axios.create({
    baseURL: url,
    headers
});