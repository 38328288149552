import React from "react";
import { Button, TextField, Typography, Box } from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';
import axios from '../../../api/axios-api';

export default class Email extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            email: "",
            isErrorEmail: false,
            errorText: "",
            disabled: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    
    async handleSubmit(event) {
        event.preventDefault();
        this.setState({
            isErrorEmail: false,
            errorText: '',
            disabled: true
        });

        if (this.state.email) {
            axios.post('auth/email', {
                volkaDeviceToken: navigator.appVersion,
                email: this.state.email
            })
                .then(response => {
                    if (response.status === 200) {
                        const confirmationToken = response.data.confirmationToken;

                        localStorage.setItem('confirmationToken', confirmationToken);
                        return this.props.history.push("/confirm");
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.setState({
                            isErrorEmail: true,
                            disabled: false,
                            errorText: error.response.data.errorMessage
                        })
                    }
                });
        } else {
            this.setState({
                isErrorEmail: true,
                disabled: false,
                errorText: 'Email address is required'
            });
        }
    }

    render() {
        let alertError = '';
        if (this.state.isErrorEmail) {
            alertError = (
                <div className="mt-3 mb-3">
                    <Alert variant="filled" severity="error">
                        <AlertTitle>Error</AlertTitle>
                        { this.state.errorText ? this.state.errorText : 'Please try again sending error occurred!'}
                    </Alert>
                </div>
            );
        }
        return (
            <div className="container">
                <div className="row justify-content-center minHeight-100">
                    <div className="col-md-6 text-center align-self-center">
                        <div className="row justify-content-center">
                            <div className="col-12 p-2 m-2">
                                <Typography component="h1" variant="h4" >
                                    <Box fontWeight="fontWeightBold" m={1}>
                                        Sign in
                                    </Box>
                                </Typography>
                            </div>
                            <div className="col-8">
                                {alertError}
                                <form onSubmit={this.handleSubmit}>
                                    <Typography variant="subtitle1">
                                        Please enter your email
                                    </Typography>
                                    <TextField
                                        type="email"
                                        placeholder="Email"
                                        fullWidth
                                        name="email"
                                        variant="outlined"
                                        value={this.state.email}
                                        onChange={(event) =>
                                        this.setState({
                                            [event.target.name]: event.target.value,
                                        })
                                        }
                                        required
                                        autoFocus
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className="m-2"
                                        disabled={this.state.disabled}
                                    >
                                        Send code
                                    </Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
