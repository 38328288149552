import React from "react";
import { Button, TextField, Typography, Box } from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';
import axios from '../../../api/axios-api';
import {Link} from 'react-router-dom';

export default class Confirm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            code: "",
            isErrorCode: false,
            disabled: false,
            errorText: ""
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({
            isErrorCode: false,
            disabled: true,
            errorText: ''
        });

        const confirmationToken = localStorage.getItem('confirmationToken');

        if (this.state.code && confirmationToken) {
            axios.post('auth/email/confirm', {
                confirmationToken: confirmationToken,
                code: this.state.code
            })
                .then(response => {
                    const authorizationToken = response.data.authorizationToken;
                    localStorage.setItem('authorizationToken', authorizationToken);
                    return this.props.history.push("/console");
                })
                .catch((error) => {
                    if (error.response) {
                        this.setState({
                            isErrorCode: true,
                            disabled: false,
                            errorText: error.response.data.errorMessage
                        })
                    }
                });
        } else {
            this.setState({
                isErrorCode: true,
                disabled: false,
                errorText: !this.state.code ? 'Code is required' : 'no confirmationToken'
            })
        }
    }

    componentDidMount() {
        if (!localStorage.getItem('confirmationToken')) {
            return this.props.history.push("/");
        }
    }

    render() {
        let alertError = '';
        if (this.state.isErrorCode) {
            alertError = (
                <div className="mt-3 mb-3">
                    <Alert variant="filled" severity="error" className="mb-2">
                        <AlertTitle>Error</AlertTitle>
                            { this.state.errorText ? this.state.errorText : 'Error occurred! Please try again.'}
                    </Alert>
                    <Link to="/">Go back</Link>
                </div>
            );
        }

        return (
        <div className="container">
            <div className="row justify-content-center minHeight-100">
                <div className="col-md-6 text-center align-self-center">
                    <div className="row justify-content-center">
                        <div className="col-12 p-2 m-2">
                            <Typography component="h1" variant="h4">
                                <Box fontWeight="fontWeightBold" m={1}>
                                    Please enter the code, sent to your mail
                                </Box>
                            </Typography>
                        </div>
                        <div className="col-8">
                            {alertError}
                            <form onSubmit={this.handleSubmit}>
                                <TextField
                                    type="text"
                                    placeholder="Code"
                                    fullWidth
                                    name="code"
                                    variant="outlined"
                                    value={this.state.code}
                                    onChange={(event) =>
                                    this.setState({
                                        [event.target.name]: event.target.value,
                                    })
                                    }
                                    required
                                    autoFocus
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    className="m-2"
                                    disabled={this.state.disabled}
                                >
                                    Sign in
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}
