import React from "react";
import { Button, Typography, Box, Card, 
    CardActionArea, CardActions, CardContent, 
    Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from '../../api/axios-api';
import {Link, Redirect} from 'react-router-dom';
import Footer from './Footer';
import './Console.scss';

const currencyGrapheme = {
    USD: '$',
    RU: 'rub',
    EUR: '€'
};

export default class Console extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            dialogLogout: false,
            code: "",
            isErrorCode: false,
            errorText: "",
            account: {
                error: "",
                loader: true,
                data: {
                    balance: 0,
                    currency: 'USD',
                    email: '',
                    id: null
                }
            },
            bottonClickAddCredit: false,
            products: {
                bottonClickProduct: false,
                error: "",
                data: null
            },
            calls: {
                loader: true,
                error: "",
                data: []
            },
            gateways: {
                productId: null,
                error: "",
                data: null
            },
            paymentError: ""
        };
    }

    componentDidMount() {
        if (!localStorage.getItem('authorizationToken')) {
            return this.props.history.push("/");
        }

        const token = 'Bearer ' + localStorage.getItem('authorizationToken');
        axios.defaults.headers.Authorization = token;

        axios.get('/account')
            .then(response => {
                this.setState({
                    account: {
                        ...this.state.account,
                        loader: false,
                        data: response.data
                    }
                })
            })
            .catch(error => {
                if (error.response && error.response) {
                    this.setState({
                        account: {
                            ...this.state.account,
                            loader: false,
                            error: 'Error get account. ' + error.response.data.errorMessage
                        }
                    });
                }
            });

            axios.get('/calls?offset=0&limit=20')
            .then(response => {
                this.setState({
                    calls: {
                        ...this.state.calls,
                        loader: false,
                        data: response.data
                    }
                })
            })
            .catch(error => {
                if (error.response && error.response) {
                    console.log('Error get calls');
                    // this.setState({
                    //     calls: {
                    //         ...this.state.calls,
                    //         error: 'Error get calls. ' + error.response.data.errorMessage,
                    //         loader: false,
                    //     }
                    // });
                }
            });
    }

    renderMyActivity() {
        let tableCalls = null;
        // new Date(1605517864018).toLocaleString()
        if (!this.state.calls || !this.state.calls.data || !this.state.calls.data.length) {
            tableCalls = (
                <TableRow hover role="checkbox" className="text-center">
                    <TableCell align="center" colSpan={4}>
                        There is no data
                    </TableCell>
                </TableRow>
            );
        } else {
            console.log(this.state.calls.data)
            tableCalls = this.state.calls.data.map(tableCall => {
                let currencySign = currencyGrapheme[tableCall.currency] ? 
                    currencyGrapheme[tableCall.currency] : '$';
                return (
                    <TableRow hover key={tableCall.id} role="checkbox">
                        <TableCell className="text-center">
                            {tableCall.toNumber}
                        </TableCell>
    
                        <TableCell className="text-center">
                            {tableCall.duration}
                        </TableCell>
    
                        <TableCell className="text-center">
                            {currencySign + tableCall.price}
                        </TableCell>
    
                        <TableCell className="text-center">
                            {new Date(tableCall.createdAt * 1000).toLocaleString()}
                        </TableCell>
                    </TableRow>
                )
            });
        }

        return (
            <Card className="mb-3">
                <CardContent className="card-background">
                    <Typography component="h1" variant="h5">
                        My activity
                    </Typography>
                </CardContent>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead className="bg-secondary">
                            <TableRow>
                                <TableCell>
                                    <Box fontWeight="fontWeightBold" m={1}>Number</Box>
                                </TableCell>
                                <TableCell>
                                    <Box fontWeight="fontWeightBold" m={1}>Duration</Box>
                                </TableCell>
                                <TableCell>
                                    <Box fontWeight="fontWeightBold" m={1}>Price</Box>
                                </TableCell>
                                <TableCell>
                                    <Box fontWeight="fontWeightBold" m={1}>Date & Time</Box>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableCalls}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        );
    }

    renderCardProducts(products) {
        const buttonProdusts = products.map(product => {
            let currencySign = currencyGrapheme[product.currency] ? 
                currencyGrapheme[product.currency] : '$';
            return (
                <Button
                    className="m-2"
                    key={product.id}
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={this.getGateways.bind(this, product.id)}
                >
                    {currencySign + product.amount}
                </Button>
            )
        })

        return (
            <div>
                {buttonProdusts}
            </div>
            );

        // return (
        //     <Card className="mb-3">
        //         <CardContent className="card-background mb-3">
        //             <Typography component="h1" variant="h5">
        //                 Products
        //             </Typography>
        //         </CardContent>
        //         {this.state.gateways.error 
        //             ? 
        //                 <div className="mt-3 mb-3">
        //                     <Alert variant="filled" severity="error" className="mb-2">
        //                         <AlertTitle>Error</AlertTitle>
        //                             { this.state.gateways.error  }
        //                     </Alert>
        //                 </div>
        //             : null
        //         }
        //         <Typography component="h1" variant="h5">
        //             Select product
        //         </Typography>
        //         <CardActions className="justify-content-center p-4">
        //             {buttonProdusts}
        //         </CardActions>
        //     </Card>
        // )
    }

    renderCardGateways(gateways) {
        const buttonGateways = gateways.map(gateways => {
            return (
                <Button
                    className="m-2"
                    key={gateways.id}
                    variant="contained"
                    color="primary"
                    type="submit"
                    onClick={this.postPayment.bind(this, gateways.id)}
                >
                    {gateways.name}
                </Button>
            )
        })

        return (
            <div>
                {buttonGateways}
            </div>
        );

        // return (
        //     <Card className="mb-3">
        //         <CardContent className="card-background mb-3">
        //             <Typography component="h1" variant="h5">
        //                 Gateways
        //             </Typography>
        //         </CardContent>
        //         {this.state.paymentError 
        //             ? 
        //                 <div className="mt-3 mb-3">
        //                     <Alert variant="filled" severity="error" className="mb-2">
        //                         <AlertTitle>Error</AlertTitle>
        //                             { this.state.paymentError  }
        //                     </Alert>
        //                 </div>
        //             : null
        //         }
        //         <Typography component="h1" variant="h5">
        //             Select gateways
        //         </Typography>
        //         <CardActions className="justify-content-center p-4">
        //             {buttonGateways}
        //         </CardActions>
        //     </Card>
        // )
    }

    getGateways(productId) {
        this.setState({
            products: {
                bottonClickProduct: true
            }
        })
        axios.get('/products/gateways?productId='+ productId).
            then(response => {
                this.setState({
                    gateways: {
                        ...this.state.gateways,
                        productId: productId,
                        data: response.data
                    }
                })
            })
            .catch(error => {
                if (error.response && error.response) {
                    this.setState({
                        products: {
                            bottonClickProduct: false
                        },
                        gateways: {
                            ...this.state.gateways,
                            error: 'Error get gateways productId. ' + error.response.data.errorMessage
                        }
                    });
                }
            });
    }

    postPayment(gatewayId) {
        const productId = this.state.gateways.productId;
        const data = {
            productId,
            gatewayId
        }
        axios.post('/payment', data)
            .then(response => {
                window.location.href = response.data.url;
            })
            .catch(error => {
                if (error.response && error.response) {
                    this.setState({
                        paymentError: 'Error payment. ' + error.response.data.errorMessage
                    });
                }
            });
    }

    addCredit = () => {
        this.setState({
            bottonClickAddCredit: true,
            products: {
                ...this.state.products,
                error: "",
            }
        })

        axios.get('/products').
            then(response => {
                this.setState({
                    products: {
                        ...this.state.products,
                        data: response.data,
                    }
                })
            })
            .catch(error => {
                if (error.response && error.response) {
                    this.setState({
                        products: {
                            ...this.state.products,
                            error: 'Error get products. ' + error.response.data.errorMessage
                        }
                    });
                }
            });
    }

    dialogLogoutClose = () => {
        this.setState({
            dialogLogout: !this.state.dialogLogout
        });
    }

    profileLogout = () => {
        localStorage.removeItem('authorizationToken');
        localStorage.removeItem('confirmationToken');
        return this.props.history.push("/");
    }

    render() {
        const {email, balance, id, currency} = this.state.account.data;
        const currencySign = currencyGrapheme[currency] ? currencyGrapheme[currency] : '$';

        let cardsRender = '';
        let buttonsrender = <CircularProgress disableShrink />;
        if (this.state.products.data && !this.state.products.bottonClickProduct) {
            buttonsrender = this.renderCardProducts(this.state.products.data)
        }
        if (this.state.gateways.data) {
            buttonsrender = this.renderCardGateways(this.state.gateways.data)
        }

        if (this.state.account.error) {
            cardsRender = (
                <div className="mt-3 mb-3">
                    <Alert variant="filled" severity="error" className="mb-2">
                        <AlertTitle>Error</AlertTitle>
                            { this.state.account.error }
                    </Alert>
                    <Link to="/">Go back</Link>
                </div>
            );
        } else {
            cardsRender = (
                <>
                    <Card className="mb-3">
                        <CardContent className="card-background">
                            <Typography component="h1" variant="h5">
                                Profile
                            </Typography>
                        </CardContent>
                        <Typography onClick={this.dialogLogoutClose} className="p-4" component="h1" variant="h5">
                                My email: {email}
                        </Typography>
                    </Card>

                    <Dialog
                        open={this.state.dialogLogout}
                        onClose={this.dialogLogoutClose}
                        aria-labelledby="draggable-dialog-title"
                    >
                        <DialogContent>
                            <Typography>
                                <Box fontWeight="fontWeightBold" m={1}>
                                    Would you like to logout?
                                </Box>
                            </Typography>
                        </DialogContent>
                        <DialogActions className="justify-content-center">
                            <Button 
                                onClick={this.profileLogout} 
                                variant="contained" 
                                type="submit" 
                                color="primary"
                            >
                                Yes
                            </Button>
                            <Button 
                                onClick={this.dialogLogoutClose} 
                                variant="contained" 
                                type="submit" 
                                color="primary"
                            >
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>
    
                    <Card className="mb-3">
                        <CardContent className="card-background">
                            <Typography component="h1" variant="h5">
                                Account Balance
                            </Typography>
                        </CardContent>
                        {this.state.products.error 
                            ? 
                                <div className="mt-3 mb-3">
                                    <Alert variant="filled" severity="error" className="mb-2">
                                        <AlertTitle>Error</AlertTitle>
                                            { this.state.products.error  }
                                    </Alert>
                                </div>
                            : null
                        }
                        <CardActions className="justify-content-between p-4">
                            <Typography className="m-2" variant="h5">{currencySign + balance}</Typography>
                            {
                                !this.state.bottonClickAddCredit 
                                ? 
                                    <Button
                                        className="m-2"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={this.addCredit}
                                    >
                                        Add credits
                                    </Button>
                                : buttonsrender
                            }
                        </CardActions>
                        <div className="row p-4 ml-1 mr-1 justify-content-center">
                            <Typography className="mr-4" variant="h5">Payment methods</Typography>
                            <img className="mr-2 ml-2" src="logo/Visa_logo.png" alt="Visa logo" width="60px" />
                            <img className="mr-2 ml-2" src="logo/MasterCard_Logo.svg" alt="Mastercard logo" width="60px" />
                        </div>
                    </Card>

                    {this.renderMyActivity()}

                    <Footer/>
                </>
            );
        }

        return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-6 text-center align-self-center">
                    <div className="row justify-content-center">
                        <div className="col-12 p-2 m-2">
                            <Typography component="h1" variant="h4">
                                <Box fontWeight="fontWeightBold" m={1}>
                                    Console
                                </Box>
                            </Typography>
                            { this.state.account.loader
                                ? <CircularProgress disableShrink />
                                : cardsRender
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}