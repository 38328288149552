import React, {Component} from 'react';
import './App.scss';
import {Route, Switch} from 'react-router-dom';
import Email from './components/auth/Email/Email';
import Confirm from './components/auth/Confirm/Confirm';
import Console from './components/Console/Console'
class App extends Component {
  render() {
    return (
        <Switch>
          <Route path="/confirm" component={Confirm} />
          <Route path="/console" component={Console} />
          <Route path="/" component={Email} />
        </Switch>
    )
  }
}

export default App
