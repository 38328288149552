import React from "react";
const Footer = () => (
    <div className="page-footer">
        <div class="container">
            <div class="page-footer__wrapper">
                <div class="page-footer__info-block">
                    <div class="page-footer__copyright-text">
                        <p>Volka International OÜ<br />
                        Tartu mnt 67/1 13b, Tallinn, Estonia 10115<br />
                        <a href="mailto:support@volkaapp.com">support@volkaapp.com</a><br />
                        +16282253210</p>
                    </div>
                    <div class="page-footer__info-wrapper">
                        <a href="https://volkaapp.com/privacy" class="page-footer__policy-link">Privacy Policy</a>
                        <a href="https://volkaapp.com/privacy" class="page-footer__terms-link">Terms of Use</a>
                    </div>
                </div>
            </div>
        </div>
    </div >
);

export default Footer;